import * as React from "react";
import { createContext, useCallback, useEffect } from "react";

const initialValues = {
    lang: "en",
    temp: "C"
};

export const AppContext = ({ children }: { children: React.ReactNode }) => {
    const [language, setLanguage] = React.useState(initialValues.lang);
    const [temperatureUnit, setTemperatureUnit] = React.useState(
        initialValues.temp
    );
    const cls = React.useRef(0);
    const clsLogged = React.useRef(false);
    const startTime = React.useRef(new Date().getTime());
    const sendClsData = useCallback((data) => {
        const timeData = {
            ...data,
            time: new Date().getTime() - startTime.current,
            pathname: location?.pathname,
            scroll: window.scrollY,
            width: window.innerWidth,
            height: window.innerHeight
        };
        if (navigator.sendBeacon) {
            let blob = new Blob([JSON.stringify(timeData)], {
                type: "application/json;charset=UTF-8"
            });

            navigator.sendBeacon(`/cls-data/`, blob);
            return;
        } else {
            const fetchProps = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8"
                },
                body: JSON.stringify(timeData)
            };

            fetch(`/cls-data/`, fetchProps);
        }
    }, []);
    useEffect(() => {
        try {
            const observer = new PerformanceObserver((entryList) => {
                try {
                    const entry = entryList.getEntries()[0];

                    // @ts-ignore
                    if (entry.hadRecentInput) {
                        return;
                    }
                    // @ts-ignore
                    const { value, sources } = entry;
                    cls.current += value;
                    if (value > 0.1 && !clsLogged.current) {
                        const attrs = sources.slice(0, 2).map((source) => {
                            return {
                                classes: source.node?.className,
                                text: source.node?.innerText,
                                prevTop: source.previousRect.top,
                                prevHeight: source.previousRect.height,
                                top: source.currentRect.top,
                                height: source.currentRect.height,
                                width: source.currentRect.width,
                                prevWidth: source.previousRect.width
                            };
                        });
                        sendClsData({
                            value,
                            attrs
                        });
                        clsLogged.current = true;
                    }

                    if (cls.current > 0.25 && !clsLogged.current) {
                        sendClsData({
                            value: cls.current
                        });
                        clsLogged.current = true;
                    }
                } catch (e) {}
            });
            observer.observe({ type: "layout-shift", buffered: true });
            return () => {
                observer.disconnect();
            };
        } catch (e) {}
    }, []);

    return (
        <>
            <LanguageContext.Provider
                value={[language, (lang) => setLanguage(lang)]}
            >
                <TemperatureUnitContext.Provider
                    value={[
                        temperatureUnit,
                        (temp) => setTemperatureUnit(temp)
                    ]}
                >
                    {children}
                </TemperatureUnitContext.Provider>
            </LanguageContext.Provider>
        </>
    );
};

export const LanguageContext = createContext<[string, (a: string) => void]>([
    null,
    (_) => {}
]);
export const TemperatureUnitContext = createContext<
    [string, (a: string) => void]
>([null, (_) => {}]);
